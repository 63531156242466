import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 640" width="30" height="30"><path fill={fill} d="M41.09 478.13C37.21 482.01 33.07 481.62 32.17 482.01C28.81 482.01 25.84 480.72 23.26 478.13C11.63 466.5 5.17 460.04 3.88 458.75C0 454.88 0.39 450.73 0 449.84C0 446.48 1.29 443.5 3.88 440.92L156.22 288.58C64.81 197.17 14.03 146.39 3.88 136.23C0 132.36 0.39 128.21 0 127.32C0 123.96 1.29 120.99 3.88 118.4C15.51 106.77 21.97 100.31 23.26 99.02C27.14 95.14 31.28 95.53 32.17 95.14C35.53 95.14 38.51 96.43 41.09 99.02C149.48 207.4 209.69 267.62 221.73 279.66C225.61 283.54 225.22 287.68 225.61 288.58C225.61 291.94 224.32 294.91 221.73 297.49C113.35 405.88 53.13 466.09 41.09 478.13ZM640 499.45C640 504.88 636.86 507.48 636.51 508.37C634.19 510.7 631.21 511.86 627.6 511.86C404.31 511.86 280.27 511.86 255.46 511.86C250.03 511.86 247.43 508.72 246.54 508.37C244.22 506.04 243.05 503.07 243.05 499.45C243.05 484.57 243.05 476.3 243.05 474.65C243.05 469.22 246.19 466.62 246.54 465.73C248.87 463.4 251.84 462.24 255.46 462.24C478.74 462.24 602.79 462.24 627.6 462.24C633.02 462.24 635.62 465.38 636.51 465.73C638.84 468.06 640 471.03 640 474.65C640 489.53 640 497.8 640 499.45Z"></path></svg>
    );
}